<template>
  <div>
    <div class="flix-form-group">
      <editBtn :onClick="function() { open = !open; calculateDates();  }">
        <template v-slot:text>
          {{ $t('message.add', {name: $tc('message.repitation', 1)}) }}
        </template>
      </editBtn>
    </div>
    <transition name="flixFadeIn">
      <div :key="open" v-if="open">
        <select class="flix-form-control serial" v-model="save.repitations" @change="calculateDates()">
          <option v-for="i in repitations" :key="i" :value="i">{{ i }} {{ $tc('message.repitation', i) }}</option>
        </select>
        <div class="flix-form-control serial" style="border: 0">
          <flixIcon id="refresh" />
        </div>
        <select class="flix-form-control serial" v-model="save.interval" @change="calculateDates()">
          <option v-for="i in count" :key="i" :value="i">{{ $getNullsBefore(i) }}</option>
        </select>
        <select class="flix-form-control serial" v-model="save.timeslot" @change="calculateDates()">
          <option value="months">{{ $t('message.months') }}</option>
          <option value="weeks">{{ $tc('message.weeks', 2) }}</option>
          <option value="days">{{ $t('message.days') }}</option>
        </select>
        <div>
          <serialMarquee :serial="newDates" :key="JSON.stringify(newDates)" />
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
export default {
  components: {
    serialMarquee () { return import('@/components/crm/addCustomer/marquee') }
  },
  props: {
    date: Object,
    callback: Function
  },
  data () {
    return {
      save: {
        timeslot: 'weeks',
        repitations: 3,
        interval: 2
      },
      open: false,
      repitations: [],
      count: [],
      newDates: []
    }
  },
  methods: {
    calculateDates () {
      this.newDates = []
      if (this.open === false) {
        this.callback(false)
        return false
      }

      var begin = this.$createDate(this.date.begin)
      var end = this.$createDate(this.date.end)

      this.newDates.push({
        begin: {
          Y: begin.getFullYear(),
          m: begin.getMonth() + 1,
          d: begin.getDate(),
          H: begin.getHours(),
          i: begin.getMinutes()
        },
        end: {
          Y: end.getFullYear(),
          m: end.getMonth() + 1,
          d: end.getDate(),
          H: end.getHours(),
          i: end.getMinutes()
        }
      })

      for (var i = 1; i <= this.save.repitations; i++) {
        var b = new Date(begin.getTime())
        var e = new Date(end.getTime())
        switch (this.save.timeslot) {
          case 'weeks':
            b.setDate(b.getDate() + (i * (7 * this.save.interval)))
            e.setDate(e.getDate() + (i * (7 * this.save.interval)))
            break
          case 'days':
            b.setDate(b.getDate() + (i * (this.save.interval)))
            e.setDate(e.getDate() + (i * (this.save.interval)))
            break
          case 'months':
            b.setMonth(b.getMonth() + (i * (this.save.interval)))
            e.setMonth(e.getMonth() + (i * (this.save.interval)))
            break
        }
        this.newDates.push({
          begin: {
            Y: b.getFullYear(),
            m: b.getMonth() + 1,
            d: b.getDate(),
            H: b.getHours(),
            i: b.getMinutes()
          },
          end: {
            Y: e.getFullYear(),
            m: e.getMonth() + 1,
            d: e.getDate(),
            H: e.getHours(),
            i: e.getMinutes()
          }
        })
      }
      this.callback(this.newDates)
    },
    setRepitations () {
      for (var i = 1; i <= 10; i++) {
        this.repitations.push(i)
      }
      this.repitations.reverse()
    },
    setCount () {
      for (var i = 1; i <= 31; i++) {
        this.count.push(i)
      }
    }
  },
  mounted () {
    this.setRepitations()
    this.setCount()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-form-control.serial
    display: inline-block
    width: inherit
</style>
